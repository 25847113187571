.changelog {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0;

  @media (min-width: $mobile-xl) {
    padding: 0 $spacing-s;
  }

  &__header {
    text-align: center;

    margin: 15px 0 15px 0;

    @media (min-width: $desktop-xs) {
      margin: 30px 0 25px 0;
    }

    @media (min-width: $desktop-m) {
      margin-top: 40px;
    }
  }

  &__entry {
    padding: 20px 10px;
    background: map-get($background, 'navy');

    @media (min-width: $mobile-xl) {
      border-radius: 16px;
      padding: 20px 30px;
    }

    @media (min-width: $desktop-xs) {
      padding: 30px 40px;
    }
  }

  &__list-item {
    display: flex;

    &:not(:first-child) {
      margin-top: 30px;
      padding-top: 29px;
      border-top: 1px solid map-get($border, 'grey-dark');
    }
  }

  &__list-item-internal-list-item {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: map-get($font, 'grey-light');
  }
}
