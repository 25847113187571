.how-to-rent {
  margin: 50px 3%;
  padding: 0 $spacing-s;

  @media (min-width: $desktop-m) {
    margin: 50px $section-gap;
  }

  .page-hero {
    margin-bottom: 100px;

    &__description {
      color: map-get($font, 'grey');
      max-width: 1920px;
    }
  }

  &-video {
    margin: 50px 0;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__step {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid map-get($border, 'navy');

    &:last-child {
      border-bottom: none;
    }

    &-header {
      width: 100%;
      margin-bottom: 30px;

      display: flex;
      justify-content: center;

      font-weight: 400;
      font-size: $font-m-4;
      color: map-get($font, 'blue-light');

      @media (min-width: $desktop-m) {
        font-size: $font-m-6;
      }
    }

    &-list {
      margin-left: 0.5em;

      li {
        margin-top: 10px;
        padding-left: 1em;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          transform: translateY(-50%);
          top: 12px;
          width: 0.25em;
          height: 0.25em;
          background-color: map-get($background, 'blue-light');
        }

        @media (min-width: $desktop-xs) {
          font-size: $font-m-3;
          margin-bottom: 0.5rem;
        }
      }

      &--nested {
        color: map-get($font, 'grey');

        li {
          font-size: $font-s-4;
        }

        li::before {
          top: 10px;
          background-color: map-get($background, 'white');
        }
      }

      &-element {
        &-header {
          font-weight: 400;
          font-size: $font-m-1;

          @media (min-width: $desktop-m) {
            font-size: $font-m-3;
          }
        }
        &-description {
          margin-top: 5px;
          font-size: $font-s-4;
          color: map-get($font, 'grey');

          @media (min-width: $desktop-m) {
            font-size: $font-m-1;
          }
        }
      }
    }
  }
}
