.download-requirements {
  &__header {
    text-align: center;
    margin: 10px 0;
    font-size: $font-m-6;

    @media (min-width: $desktop-xs) {
      text-align: left;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 0px;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 15px;
      background: map-get($background, 'navy-gradient');
      border-radius: 6px;
      opacity: 0.7;
    }

    &-title {
      font-size: $font-s-3;
    }
  }
}
